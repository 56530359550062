// BXSLIDER

.bx-cont { margin: 0 auto; max-width: 90%; text-align: center; color: #fff; position: absolute; left: 0; right: 0; margin: 0 auto; bottom: 230px; 
  
  @media only screen and (max-width: 990px) { display: none; }

  ul#bxslider { 

      li { padding: 0; background: none; text-align: center; max-width: 100%; margin: 0 auto; color: #FFF; font-size: 75px;
      
        @media only screen and (max-width: 1500px) { font-size: 60px; }
        @media only screen and (max-width: 1250px) { font-size: 50px; }
      }
  }
}

// ---------------------------------------------

.bx-cont2 { width: 70%; margin: 0;

  @media only screen and (max-width: 990px) { width: 100%; }

  ul#bxslider2 { 

      li { padding: 0; background: none; text-align: left; max-width: 100%; margin: 0 auto; color: #FFF; font-size: 22px; font-weight: 400; line-height: 1.4;

        @media only screen and (max-width: 1800px) { font-size: 25px; }
        @media only screen and (max-width: 1500px) { font-size: 22px; }
        @media only screen and (max-width: 1300px) { font-size: 20px; }
        @media only screen and (max-width: 990px) { font-size: 18px; }

        span { display: block; text-transform: uppercase; font-size: 16px; margin: 50px auto 0; font-weight: 400; 
        
          @media only screen and (max-width: 990px) { margin: 20px auto 0; }
        }
      }
  }
}

// BXSLIDER CONTROLS - INDICATORS

.bx-controls{ display: block; text-align: left; border-radius: 50%; margin: 40px 0 0 0;

  @media only screen and (max-width: 990px) { margin: 20px auto 0;  text-align: center; }

  .bx-pager-item { display: inline-block; height: 7px; width: 30px; margin: 0 15px 0 0; border-radius: 30px;

    .bx-pager-link { height: 7px; width: 30px; background: #585858; overflow: hidden;color: transparent; display: inline-block; margin: 0 auto; border-radius: 30px; outline-offset: -1px; }

      .active { background: #fff;}
  }
}

// BXSLIDER CONTROLS - ARROWS

	// .bx-prev {position: absolute; color: transparent; left: 0; top: 250px; width: 28px; height: 30px; background: url(/i/design/news-left.png) no-repeat center; background-size: contain;}

  // .bx-next {position: absolute; color: transparent; right: 0; top: 250px; width: 28px; height: 40px; background: url(/i/design/news-right.png) no-repeat center; background-size: contain;}
    

// for c_import in the CMS

// [bx-container] {margin: 50px auto; left: 0; right: 0; top: 0;}
// [bx-container]  li {border: 5px dashed #fff; background: #222!important; padding: 10px; color: #fff!important;}