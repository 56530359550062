// &:before { @include before(100px, 100px); background: url(/i/design/) no-repeat center; top: 0; left: 0; } 

// --------------- --------------------//
// FONTS
// -----------------------------------//

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;600;700;900&display=swap');

// -----------------------------------//
// VARIABLES
// -----------------------------------// 

$link-color: #e11c00;

$h1-color: #007481;
$h2-color: #f4e4b1;
$h3-bg-color: #f4e4b1;
$h4-bg-color: #636466;

// -----------------------------------//
// BODY
// -----------------------------------//

body {
	text-align: center;
	padding: 0;
	margin: 0;
	position: relative;
	overflow-x: hidden;
	font-family: 'Lato', sans-serif;
}

.body_3636 {}

// -----------------------------------//
// GLOBAL
// -----------------------------------//

.body_3636 * {
	box-sizing: border-box;
}

body a {
	color: $link-color;
	text-decoration: none;
	transition: .5s;
}

body a:hover,
body a:focus {
	text-decoration: none;
}

a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {}

h1 {
	font-size: 52px;
	margin: 0 0 20px 0;
	color: $h1-color;
	line-height: 1.2;
}

h2 {
	font-size: 35px;
	margin: 25px 0 20px 0;
	color: $h2-color;
	line-height: 1.3;
}

h3 {
	font-size: 20px;
	margin: 25px 0 10px 0;
	padding: 5px 10px 3px 10px;
	background: $h3-bg-color;
	color: #fff;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
	background-color: $h4-bg-color;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

p {
	margin: 12px 0;
	padding: 0;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
}

hr {
	height: 1px;
	border-width: 0;
	color: #f2f2f2;
	background-color: #f2f2f2;
}

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
	max-width: 2000px;
	overflow: hidden;
}

.wrapper {
	width: 990px;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
	position: relative;
}

.wrapper-inside {
	width: 1500px;
	max-width: 90%;
	margin: 0 auto;
	@extend .clearfix;
	position: relative;
}

// fix home page wrapper called in t.asp
.body_3636 .wrapper-inside {
	width: 100%;
	max-width: 100%;
	padding: 0;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	padding: 0;
}

header>.wrapper {
	padding: 0;
	position: relative;
	z-index: 60;
}

header h1 {
	margin: 0;
	background: none;
}

#logo-index {
	margin: 0 auto;
	padding: 0;
	max-width: 85%;
	display: inline-block;

	a {
		img#logo {
			display: block;
			margin: 0 auto;
			padding: 100px 0 0 0;
		}
	}
}

.contact {
	height: 350px;
	width: 50px;
	position: fixed;
	right: 0;
	top: calc(50% - 150px);
	background: rgba(#2b2762, .9);
	z-index: 99;

	@media only screen and (max-width: 990px) {
		display: none;
	}

	h2 {
		position: absolute;
		transform: rotate(90deg);
		left: -46px;
		top: 147px;
		z-index: 99;
		white-space: nowrap;
		margin: 0;
		color: #fff;
		font-size: 30px;
	}
}

// -----------------------------------//
// SOCIAL DOCK
// -----------------------------------//

#social-dock {
	position: absolute;
	left: 8%;
	top: 34px;
	z-index: 999;

	@media only screen and (max-width: 1500px) {
		top: 80px;
		left: 5%;
	}

	@media only screen and (max-width: 990px) {
		position: relative;
		left: 0;
		right: 0;
		margin: 0 auto;
		top: 50px;
	}

	li {
		display: inline-block;
		@include box(25px);
		padding: 0;
		background: none;
		margin: 0 7px;

		a {
			display: block;
			@include box(100%);
		}

		#twitter-head {
			@include box(25px);
			overflow: hidden;
			z-index: 99;
			background: url(/i/design/tw-ic.png) no-repeat center;
		}

		#instagram-head {
			@include box(25px);
			overflow: hidden;
			z-index: 99;
			background: url(/i/design/in-ic.png) no-repeat center;
		}
	}
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

#google_translate_element {
	opacity: 0;
}

.translate {
	@include box(25px);
	overflow: hidden;
	background: url(/i/design/translate-ic.png) no-repeat center;
	z-index: 999;
}

.goog-te-gadget .goog-te-combo {
	padding: 10px;
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

a#FS {
	@include box(25px);
	background: url(/i/design/search-ic.png) no-repeat center;
	padding: 0px;
	transition: all .3s ease;
	cursor: pointer;
}

/* Search Style */
#search {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	transition: all 0.5s ease-in-out;
	transform: translate(0px, -100%) scale(0, 0);
	opacity: 0;
	display: none;
}

#search.open {
	transform: translate(0px, 0px) scale(1, 1);
	opacity: 1;
	z-index: 9999999;
	display: block;
}

#search input[type="search"] {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -51px;
	width: 60%;
	margin-left: 20%;
	color: rgb(255, 255, 255);
	background: transparent;
	border-left: 0px solid transparent;
	border-right: 0px solid transparent;
	font-size: 40px;
	font-weight: 300;
	text-align: center;
	outline: none;
	padding: 10px;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: lightblue;
$search-highlight-color: darkblue;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

#mainholder {
	width: 100%;
	margin: 0px auto;
	padding: 30px 0 30px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
}

// home page alternate
.body_3636 #mainholder {
	padding: 0;
	margin: 0;
}

// -----------------------------------//
// WELCOME SEC
// -----------------------------------//

.btns {
	position: relative;
	width: 100%;
	height: 340px;
	margin: 0;

	@media only screen and (max-width: 990px) {
		height: auto;
		@extend .clearfix;
	}

	li {
		display: inline-block;
		width: 25%;
		height: 100%;
		float: left;
		padding: 0;
		background: none;
		position: relative;
		margin: 0;
		overflow: hidden;

		@media only screen and (max-width: 990px) {
			width: 50%;
			height: 200px;
		}

		@media only screen and (max-width: 400px) {
			width: 100%;
			float: none;
			display: block;
		}

		&:nth-of-type(2) a {

			&:before {
				@include before(100%, 100%);
				background: url(/i/design/btn1.png) no-repeat top left;
				top: 0;
				left: 0;
				z-index: 1;
				transition: .3s;
			}
		}

		a {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 2;
			display: block;
			width: 100%;
			height: 100%;

			&:before {
				@include before(100%, 100%);
				background: url(/i/design/btn2.png) no-repeat top right;
				top: 0;
				right: 0;
				z-index: 1;
				transition: .3s;
			}
		}

		img {
			position: absolute;
			left: 0;
			top: 0;
			min-width: 100%;
			min-height: 100%;
			object-fit: cover;
			transition: .5s;
			transition-delay: .2s;
		}

		span {
			@include center;
			color: #fff;
			top: auto;
			bottom: 20px;
			width: 100%;
			text-align: center;
			font-size: 30px;
			margin: 0;
			line-height: 1;
			z-index: 2;

			@media only screen and (max-width: 1500px) {
				font-size: 25px;
			}

			@media only screen and (max-width: 1250px) {
				font-size: 20px;
			}

			@media only screen and (max-width: 990px) {
				font-size: 18px;
			}
		}

		// &:nth-of-type(3) {
		// 	span { bottom: 5px; }
		// }

		&:hover {
			img {
				transform: scale(1.2);
				filter: brightness(130%);
			}

			a:before {
				transform: scale(1.2);
			}
		}
	}
}

// -----------------------------------//

#welcome-sec {
	padding: 120px 0 150px;
	background: #f3f3f3;
	position: relative;
	z-index: 1;
	color: #666;
	overflow: hidden;

	@media only screen and (max-width: 1800px) {
		padding: 100px 0 130px;
	}

	@media only screen and (max-width: 1500px) {
		padding: 90px 0 110px;
	}

	@media only screen and (max-width: 1300px) {
		padding: 80px 0 100px;
	}

	@media only screen and (max-width: 990px) {
		padding: 80px 0;
	}

	&:before {
		@include before(568px, 800px);
		background: url(/i/design/welcome-bg.png) no-repeat center top;
		right: 0;
		bottom: 0;

		@media only screen and (max-width: 1800px) {
			right: -50px;
		}

		@media only screen and (max-width: 1700px) {
			right: -100px;
			transform: scale(.8);
			bottom: -120px;
		}

		@media only screen and (max-width: 1600px) {
			right: -150px;
		}

		@media only screen and (max-width: 1500px) {
			right: -200px;
		}

		@media only screen and (max-width: 1400px) {
			right: -250px;
		}

		@media only screen and (max-width: 1300px) {
			right: -300px;
			transform: scale(.7);
			bottom: -130px;
		}

		@media only screen and (max-width: 990px) {
			transform: none;
			right: 0;
			opacity: .15;
		}
	}

	.wrapper {
		padding: 0 8% 0 0;

		@media only screen and (max-width: 1800px) {
			padding: 0 10% 0 0;
		}

		@media only screen and (max-width: 1700px) {
			padding: 0 11% 0 0;
		}

		@media only screen and (max-width: 1600px) {
			padding: 0 12% 0 0;
		}

		@media only screen and (max-width: 1500px) {
			padding: 0 13% 0 0;
		}

		@media only screen and (max-width: 1400px) {
			padding: 0 20% 0 5%;
		}

		@media only screen and (max-width: 990px) {
			padding: 0 5%;
		}
	}

	h2 {
		text-transform: none;
		margin: 0 0 50px 0;
		color: #444;
		font-size: 30px;

		@media only screen and (max-width: 990px) {
			font-size: 25px;
		}

		span {
			display: block;
			text-transform: uppercase;
			font-size: 17px;
			font-weight: 600;
		}
	}

	p {
		font-size: 17px;
		font-weight: 500;
		color: #444;

		.head-cont {
			float: left;
			margin: 0 20px 30px 0;
			padding: 0 10px 0 0;
			box-shadow: 2px 0 0 0 #a8a8a8;

			@media only screen and (max-width: 990px) {
				float: none;
				display: block;
				margin: 0 auto 40px;
				max-width: 250px;
				box-shadow: none;
				padding: 0;
			}

			img {
				display: block;
				margin: 0 0 10px 0;
				width: 100%;
				outline: 2px solid #d5d5d5;
				outline-offset: -1px;
			}

			span {
				font-size: 15px;
				line-height: 1.2;
				display: block;
				font-weight: 600;
			}
		}

		&:last-of-type {
			clear: both;
			padding: 0 15% 0 0;

			strong {
				display: block;
				margin: 20px 0;
				font-weight: 600;
			}

			@media only screen and (max-width: 990px) {
				padding: 0;
			}
		}
	}

	.read-more {
		display: block;
		height: 60px;
		line-height: 60px;
		width: 300px;
		max-width: 95%;
		text-align: center;
		text-transform: uppercase;
		box-shadow: 0 0 0 1px #666;
		position: relative;
		z-index: 3;
		color: #666;
		margin: 50px 0 0 0;
		font-size: 15px;
		font-weight: 600;

		@media only screen and (max-width: 990px) {
			margin: 50px 0 0 0;
		}

		&:before {
			@include before(calc(100% - 30px), calc(100% + 30px));
			@include center;
			background: #006C81;
			z-index: -1;
			opacity: .15;
			transition: .3s;
		}

		&:hover {
			color: #fff;

			&:before {
				@include before(calc(100% + 30px), calc(100% - 20px));
				opacity: 1;
			}
		}
	}
}

// -----------------------------------//

#trust-sec {
	padding: 80px 5%;
	background: url(/i/design/trust-sec-bg.jpg) no-repeat top center;
	border-top: 5px solid #fff;

	@media only screen and (max-width: 990px) {
		background: #262164;
	}

	.wrapper {
		max-width: 100%;
	}

	h2 {
		color: #fff;
		line-height: 1;
		margin: 0 0 30px 0;

		img {
			display: block;
			margin: 25px 0 0 0;
		}
	}

	p {
		color: #fff;
		margin: 0;
		font-weight: 300;

		&:not(:last-of-type) {
			margin: 0 0 20px 0;
		}
	}

	.read-more {
		display: block;
		height: 60px;
		line-height: 60px;
		width: 300px;
		max-width: 95%;
		text-align: center;
		text-transform: uppercase;
		box-shadow: 0 0 0 1px #fff;
		position: relative;
		z-index: 3;
		color: #fff;
		margin: 50px 0 0 0;
		font-size: 15px;
		font-weight: 600;

		position: absolute;
		right: 0;
		top: -20px;

		@media only screen and (max-width: 990px) {
			position: relative;
			left: 0;
			right: 0;
			margin: 0;
			top: auto;
			margin: 50px 0 0 0;
		}

		&:before {
			@include before(calc(100% - 30px), calc(100% + 30px));
			@include center;
			background: #fff;
			z-index: -1;
			opacity: .15;
			transition: .3s;
		}

		&:hover {
			color: #262164;

			&:before {
				@include before(calc(100% + 30px), calc(100% - 20px));
				opacity: 1;
			}
		}
	}
}

#testimonials-sec {
	padding: 80px 0;
	background: url(/i/design/test-bg.jpg) no-repeat top center;
	border-top: 5px solid #fff;

	@media only screen and (max-width: 1800px) {
		padding: 70px 0;
	}

	@media only screen and (max-width: 1500px) {
		padding: 60px 0;
	}

	@media only screen and (max-width: 1300px) {
		padding: 50px 0;
	}

	@media only screen and (max-width: 990px) {
		padding: 0;
		background: none;
	}

	.quotas {
		width: 50%;
		float: left;
		text-align: left;

		@media only screen and (max-width: 990px) {
			width: 100%;
			padding: 50px 5%;
			background: #454545;
			text-align: center;
			float: none;
		}

		h2 {
			font-size: 50px;
			color: #fff;
			margin: 0 0 50px 0;

			@media only screen and (max-width: 1800px) {
				font-size: 35px;
				margin: 0 auto 40px;
			}

			@media only screen and (max-width: 1500px) {
				font-size: 32px;
				margin: 0 auto 35px;
			}

			@media only screen and (max-width: 1300px) {
				font-size: 30px;
				margin: 0 auto 30px;
			}

			@media only screen and (max-width: 990px) {
				font-size: 28px;
				margin: 0 auto 25px;
			}

			img {
				display: block;
				margin: 20px 0;

				@media only screen and (max-width: 990px) {
					display: none;
				}
			}
		}
	}

	.why-choose {
		width: 50%;
		float: right;
		text-align: right;
		padding: 0 0 0 50px;

		@media only screen and (max-width: 990px) {
			width: 100%;
			padding: 50px 5%;
			background: #fff;
			text-align: center;
			float: none;
		}

		h2 {
			font-size: 50px;
			color: #454545;
			margin: 0 0 50px 0;
			line-height: 1;

			@media only screen and (max-width: 1800px) {
				font-size: 35px;
				margin: 0 auto 40px;
			}

			@media only screen and (max-width: 1500px) {
				font-size: 32px;
				margin: 0 auto 35px;
			}

			@media only screen and (max-width: 1300px) {
				font-size: 30px;
				margin: 0 auto 30px;
			}

			@media only screen and (max-width: 990px) {
				font-size: 28px;
				margin: 0 auto 25px;
			}
		}

		p {
			width: 87%;
			text-align: right;
			margin: 0 0 30px auto;
			color: #454545;
			font-size: 24px;
			letter-spacing: -0.02em;

			@media only screen and (max-width: 990px) {
				text-align: center;
				font-size: 20px;
				width: 100%;
			}
		}

		.read-more {
			display: block;
			height: 60px;
			line-height: 60px;
			width: 300px;
			max-width: 95%;
			text-align: center;
			text-transform: uppercase;
			box-shadow: 0 0 0 1px #454545;
			position: relative;
			z-index: 3;
			color: #454545;
			margin: 70px 0 0 auto;
			font-size: 15px;
			font-weight: 600;

			@media only screen and (max-width: 990px) {
				margin: 50px auto 0;
			}

			&:before {
				@include before(calc(100% - 30px), calc(100% + 30px));
				@include center;
				background: #006C81;
				z-index: -1;
				transition: .3s;
				opacity: .15;
			}

			&:hover {
				color: #fff;

				&:before {
					@include before(calc(100% + 30px), calc(100% - 20px));
					opacity: 1;
				}
			}
		}
	}
}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column {
	width: 100%;
	max-width: 1300px;
	margin: 0 auto 50px;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	background: rgba(255, 255, 255, .95);
	@extend .clearfix;
	border-radius: 20px 40px 10px 0;
	box-shadow: 0 0 0 3px rgba(#fff, .2);

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 2
.Content2Column {
	width: calc(92% - 350px);
	margin: 0 0 50px 8%;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	display: inline-block;
	float: left;
	background: rgba(255, 255, 255, .95);
	@extend .clearfix;
	border-radius: 20px 40px 10px 0;
	box-shadow: 0 0 0 3px rgba(#fff, .2);

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 350px;
	text-align: left;

	img {
		max-width: 100%;
		height: auto;
	}
}

#mainholder {

	tr,
	td {
		border: 1px solid #BFBFBF;
	}

	td {
		padding: 5px;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto 50px;
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 300px;
	margin: 0 20px 0 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	display: inline-block;
	float: right;
	box-sizing: border-box;
	border-radius: 20px 40px 10px 0;
	box-shadow: 0 0 0 3px rgba(#fff, .5);
	background: rgba(0, 0, 0, .4);
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 0;
	padding: 0;
	text-align: center;
}

// List
ul.SideList {
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none;
	text-align: left;
}

ul.SideList li {
	margin: 0;
	padding: 0;
	background: none;
}

ul.SideList li a {
	margin: 0;
	padding: 0px;
	color: #000;
	transition: .5s;
	font-weight: normal;
	display: block;
}

ul.SideList li a:hover {
	transition: .5s;
	text-decoration: none;
}

ul.SideList li a.child {
	background: url(/i/design/svg/ic-related-pages.svg) left 20px center no-repeat;
	padding-left: 40px !important;
}

// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	text-align: center;
	font-size: 20px;
	line-height: 130%;
	transition: .5s;

	// Span = number
	span {
		display: block;
		transition: .5s;
	}
}

ul.SideList p.title {
	float: right;
	width: 100%;
	font-size: 16px;
	transition: .5s;

	// Truncate
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		height: 35px;
	}
}

// More links
.SideHeading a.more {
	display: inline-block;
	font-size: 18px;
	padding: 0px;
	transition: .5s;

	&:hover {
		transition: .5s;
		text-decoration: none;
	}
}

// -----------------------------------//
// RELATED PAGES
// -----------------------------------//

#SideHeadingPages {
	position: relative;
	overflow: hidden;
	z-index: 1;
	padding: 5%;

	h2 {
		display: block;
		text-align: center;
		font-size: 28px;
		padding: 20px 0;
		margin: 0;
		color: #fff;
	}

	ul.SideList {
		padding: 0 0 30px 0;

		li {
			a {
				padding: 7px 20px;
				font-size: 18px;
				color: #fff;

				&:hover {
					background-color: rgba(255, 255, 255, .1);
				}
			}
		}
	}
}

// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home {
	background: rgba(0, 0, 0, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8000;

	.message-modal {
		position: absolute;
		top: calc(50% - 300px);
		left: calc(50% - 465px - 40px - 10px);
		width: 930px;
		height: auto;
		margin: 0 30px;
		padding: 20px;
		background: rgba(255, 255, 255, .9);

		.modal-content {
			background: rgba(255, 255, 255, .9);
			background-position: -120px 50px;
			box-shadow: inset 0 0 0 1px #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			text-align: center;

			.modal-text {
				display: block;
				padding: 45px;

				p {
					margin: 0;
				}
			}

			a.close-modal {
				position: absolute;
				top: 31px;
				right: 31px;
			}
		}
	}
}

.hide-modal {
	display: none !important;
}

// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards {
	width: 100%;
	overflow: hidden;
	background: #fff;
	padding: 20px 0;

	.CMSawards {
		overflow: hidden;
		width: 100%;
		height: 100%;
		margin: 0 auto !important;
		background: none !important;

		ul {
			margin: 0;

			li {
				background: none;
				padding: 0 5px;
				margin: 0 10px;
				display: inline-block;

				img {
					max-height: 70px;
					transform: none !important;
				}
			}
		}
	}
}

// -----------------------------------//
// SEASONAL FX
// -----------------------------------//

.seasonaleffect {
	z-index: 999 !important;
}

// -----------------------------------//
// FOOTER
// -----------------------------------//

footer {
	margin: 0 auto;
	clear: both;
	background: url(/i/design/footer-bg.jpg) no-repeat top center;
	padding: 50px 0 46px;
	position: relative;
	@extend .clearfix;
	border-top: 5px solid #fff;
	background-size: cover;

	@media only screen and (max-width: 1300px) {
		background-position: top right -200px;
	}

	@media only screen and (max-width: 990px) {
		background: $h1-color;
	}

	.wrapper {
		text-align: left;
		font-size: 17px;
		float: right;
		margin: 0 10% 0 0;

		@media only screen and (max-width: 1500px) {
			margin: 0 3% 0 0;
		}

		@media only screen and (max-width: 990px) {
			margin: 0 auto;
			float: none;
		}
	}

	p,
	a[href^="mailto:"] {
		margin: 0;
		color: #fff;
	}

}

.details {
	float: right;
	width: 700px;

	@media only screen and (max-width: 990px) {
		width: 100%;
		float: none;
	}

	.company-info {
		margin: 40px 0 0 0;

		img,
		p {
			display: inline-block;
			vertical-align: middle;

			@media only screen and (max-width: 990px) {
				display: block;
			}
		}

		p {
			width: calc(100% - 120px);
			font-size: 13px;

			@media only screen and (max-width: 990px) {
				width: 100%;
			}
		}

		img {
			margin: 0 20px 0 0;

			@media only screen and (max-width: 990px) {
				margin: 0 auto 20px;
			}
		}

		@media only screen and (max-width: 990px) {
			width: 400px;
			max-width: 95%;
			float: none;
			display: block;
			margin: 0 auto;
		}
	}
}

h2.footer-heading {
	padding: 0;
	margin: 0 0 10px 0;
	color: #fff;
	font-size: 22px;
	font-weight: 600;
}

.footer-img {
	float: left;
	margin: 0 0 50px 0;
	margin: 0 0 25px -100px;

	@media only screen and (max-width: 1500px) {
		margin: 10px 0 78px -50px;
	}

	@media only screen and (max-width: 1300px) {
		margin: 10px 0 78px 80px;
	}

	@media only screen and (max-width: 990px) {
		width: auto;
		float: none;
		display: block;
		margin: 0 auto 40px;
	}
}

.footer-img1 {
	display: block;

	@media only screen and (max-width: 990px) {
		display: none;
	}
}

.footer-img2 {
	display: none;

	@media only screen and (max-width: 990px) {
		display: block;
	}
}

p.address {
	margin: 0 0 20px 0;
}

p.telephone {
	display: inline-block;
}

.company-info {
	margin: 40px 0 0 0;

	@media only screen and (max-width: 990px) {
		margin: 20px auto !important;
		display: block;
	}
}

footer a[href^="mailto:"] {
	display: inline-block;
}

.sub-footer {
	float: left;
	width: 350px;
	clear: left;
	margin: 0 0 0 -100px;

	@media only screen and (max-width: 1500px) {
		margin: 0 0 0 -50px;
	}

	@media only screen and (max-width: 1300px) {
		margin: 0 0 0 80px;
	}

	@media only screen and (max-width: 990px) {
		width: auto;
		float: none;
		display: block;
		margin: 0 auto;
	}
}

#copyright,
#credit {
	float: left;
	font-size: 13px;
	color: #222;

	@media only screen and (max-width: 990px) {
		width: auto;
		float: none;
		display: block;
		color: #fff;
	}

	a {
		color: #222;

		@media only screen and (max-width: 990px) {
			color: #fff;
		}

		&:last-of-type {
			font-weight: bold;
		}
	}
}


// -----------------------------------//
// TOP BUTTON
// -----------------------------------//

.top-btn {
	position: fixed;
	z-index: 99999;
	bottom: 50px;
	right: 30px;
	display: block;
	width: 45px;
	height: 45px;
	background: rgba(0, 0, 0, .3);
	border-radius: 50%;
	color: #e11c00;
	font-size: 25px;
	text-align: center;
	opacity: 0;
	transform: scale(0);
	transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

	@media only screen and (max-width: 1199px) {
		display: none;
	}
}

.show-top-btn {
	opacity: 1;
	transform: scale(1);
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: #222;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p {
	text-align: center;
}

$blockquote-background-color: #fff;
$blockquote-border-color: #eeeeee;
$blockquote-color: #222;
$blockquote-speech-mark-color: #222;
$blockquote-font-size: 1em;