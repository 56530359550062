.boxmenuright, .boxmenuleft			{border:2px solid #CCC; float:right; padding:10px 10px 6px 10px; margin:0 0 10px 20px; width: 206px;} 
.boxmenuleft						{float:left; margin:0 10px 0 20px;}
.boxmenuright h2, .boxmenuleft h2	{border-bottom:1px dotted #CCC; font-weight:normal; font-size:1.2em; margin: 0 0 10px 0; padding:0 0 5px 0;}
.boxmenuright li, .boxmenuleft li	{background-image:url(/i/icons/page.gif)}
.boxout, .boxoutright, .boxoutpurple, .boxoutpurpleright, .boxoutgreen, .boxoutgreenright, .boxoutblue, .boxoutblueright	{float:left; background-color:#FF5924; display:inline; padding:10px; margin:2px 20px 1px 0; width: 230px; color:#FFF; font-size:1.2em;}
.boxoutpurple, .boxoutpurpleright			{background-color:#663d7f;}
.boxoutgreen, .boxoutgreenright				{background-color:#009b66;}
.boxoutblue, .boxoutblueright				{background-color:#00639c;}
.boxoutright, .boxoutpurpleright, .boxoutgreenright, .boxoutblueright				{float:right; margin:2px 0 1px 20px;}
.boxout p, .boxoutright p, .boxoutpurple p, .boxoutpurpleright p, .boxoutgreen p, .boxoutgreenright p, .boxoutblue p, .boxoutblueright	p	{margin:0; color:#FFF;}
div.boxout a, div.boxoutright a, div.boxoutpurple a, div.boxoutpurpleright a, div.boxoutgreen a, div.boxoutgreenright a, div.boxoutblue a, div.boxoutblueright a	{color:#FFF}
div.boxout a:hover, div.boxoutright a:hover, div.boxoutpurple a:hover, div.boxoutpurpleright a:hover, div.boxoutgreen a:hover, div.boxoutgreenright a:hover, div.boxoutblue a:hover, div.boxoutblueright a:hover		{background-color:none; background:transparent; text-decoration:underline;}
.button 			{background-color:#666;color:#FFF;	border:1px solid #333;	padding:1px 2px;	margin:10px 0;	font-weight:bold;}
.floatleft			{float:left; margin:0 10px 2px 0;}
.floatright			{float:right; margin:0 0 2px 10px;}
.greytext			{color:#CCC;}
.imageFullWidthborder		{border:1px solid #666; margin:0 0 10px 0; display:block;}
.imageleft			{float:left; margin:0 17px 1px 0;}
.imageleftborder	{float:left; margin:0 17px 1px 0; border:1px solid #CCC;}
.imageright, .imageRight {float:right; margin:0 0 1px 17px;}
.imagerightborder, .imageRightBorder	{float:right; margin:0 0 1px 17px; border:1px solid #CCC;}
div.imageleftborder	{float:left; margin:0 17px 1px 0; display:inline;}
div.imagerightborder, div.imageRightBorder	{float:right; margin:0 0 1px 17px; display:inline;}
div.imagerightborder img, div.imageRightBorder img	{}
div.imagerightborder p, div.imageleftborder	p, div.imageRightBorder p, div.imageLeftBorder	p {margin:0; padding:1px 5px; background-color:#CCC; font-size:0.9em;}
img.imagerightborder, img.imageRightBorder	{float:right; border:1px solid #CCC; margin:0 0 1px 17px;}
.message, p.message {padding:10px;	background-color:#CC0033;	color:#FFF;	border:1px solid #990033;}
blockquote, q {quotes: none;}
blockquote {margin: 40px 0; background: $blockquote-background-color; padding: 5%; position: relative; color: $blockquote-color; border:1px solid $blockquote-border-color;	font-size: $blockquote-font-size; }
blockquote:before, blockquote:after {content: "\201C"; position: absolute; font-size: 60px; line-height: 1; color: $blockquote-speech-mark-color; font-style: normal;}
blockquote:before {top: 10px; left: 10px;}
blockquote:after {content: "\201D"; right: 10px; bottom: -20px;}
.tableborder	{border-bottom:1px solid $table-border-color; border-left:1px solid $table-border-color; margin:20px 0;}
.tableborder td, .tableborder th	{padding:10px; margin:0; font-weight:normal; border-right:1px solid $table-border-color; border-top:1px solid $table-border-color; vertical-align:top; text-align:left;}
.tableborder td p	{margin: 0;}
.tableborder td.tablecellhighlight, .tableborder th.tablecellhighlight, .tablecellhighlight	{background-color: $table-highlight-background-color; color: $table-highlight-color; font-weight: $table-highlight-font-weight;}
.tablenoborderpadded	{border:0; margin:10px 0 0 -10px;}
.tablenoborderpadded td, .tablenoborderPadded th	{padding:10px 0 10px 10px; margin:0; font-weight:normal; border:0; vertical-align:top; text-align:left;}
.tablenoborderpadded td p	{margin: 0;}
.tablenoborderpadded td.tablecellhighlight, .tablenoborderpadded th.tablecellhighlight {background-color: $table-highlight-background-color; color: $table-highlight-color; font-weight: $table-highlight-font-weight;}
.tableborder thead{border-right: 1px solid #00008b; border-top: 1px solid #00008b;}
.tableborder thead th{border-right: 1px solid #00008b;}

.search-table	{border-bottom:none; border-left:none; margin:20px 0;}
.search-table td, .tableborder th	{padding:10px; margin:0; font-weight:normal; vertical-align:top; text-align:left;}
.search-table tr{
	&:nth-of-type(odd){background: $search-odd-tr-background;}
}
.search-table td p	{margin: 0;}
.search-table td.tablecellhighlight, .search-table th.tablecellhighlight, .tablecellhighlight	{padding: 8px 10px; font-size: $search-highlight-font-size;background-color: $search-highlight-background-color; color: $search-highlight-color; font-weight: $search-highlight-font-weight;}

.tableborder4px td,
.tableborder4px th{padding: 2px;margin: 0; font-weight: normal; border: 1px solid #00008b; vertical-align: top; text-align: left;}

// -----------------------------------//
// FORMS
// -----------------------------------//

form  { margin:0; padding:0; @extend .clearfix; }
fieldset { border: 1px solid #c0c0c0; margin: 0 2px; padding: 0.35em 0.625em 0.75em; }
legend  { border: 0; padding: 0; white-space: normal; *margin-left: -7px; }
button, html input[type="button"], input[type="reset"], input[type="submit"] { -webkit-appearance: button; cursor: pointer; *overflow: visible; /* 4 */ }
button[disabled], html input[disabled] { cursor: default; }
input[type="checkbox"], input[type="radio"] { box-sizing: border-box; padding: 0; *height: 13px; *width: 13px; }
input[type="search"] 	{ -webkit-appearance: textfield; box-sizing: content-box; }
input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }
button::-moz-focus-inner, input::-moz-focus-inner { border: 0; padding: 0; }
textarea { resize: vertical; overflow: auto; vertical-align: top; }
label { float: left; margin: 10px 0; width: 90px; display: inline; }
form .formfield { margin: 10px; float: left; display: inline; width: 200px; border: 1px solid #CCC; padding: 2px; }
form div { margin: 15px 0; display: block; clear: left; vertical-align: top; }
form div input[type="text"], form div textarea, form span textarea, form div input[type="textarea"], form div input[type="password"] { border: 1px solid #CCC; padding: 4px; width: 300px; margin: 0; background-color: #EFEFEF; }
input[type="text"]:focus, form div textarea:focus, form span textarea:focus, form div input[type="textarea"]:focus, form div input[type="password"]:focus { border-color: #000; }
form div span.label label, form div span.label,span.spacer,span.multiple span { width: 180px; float: left; margin: 0 0 0 0; }
form div span.multiple  { float: left; }
form div span.button    { padding-left: 180px; background: transparent; border: 0; }
form div span.button input	{ background: #666; color:#FFF; margin: 0; padding: 8px 10px; font-weight:bold; border: 0; }
form div span.button input:hover { background: #888; }
form div.clear { clear: both; padding-top: 5px; }

// -----------------------------------//
// CALENDAR TABLE BACKGROUND
// -----------------------------------//

.fc-content { background: none; }

// -----------------------------------//
// IE WARNING
// -----------------------------------//

#ie-warning { width: 100%; background: #b20000; color: #fff; overflow: hidden; padding: 0 0 10px 0; transition: .5s; display: block }
#ie-warning p	{ margin: 5px auto; }
#ie-warning a { color: #fff; text-decoration:underline; cursor: pointer; }

// -----------------------------------//
// BROWSER DETECTION
// -----------------------------------//

#browserblast         { position: fixed; top: 0; left: 0; width: 100%; height: 40px; border-bottom: 1px solid #D4C790; font-family: sans-serif; font-size: 12px; font-weight: bold; line-height: 40px; color: #6d6647; text-align: center; background: #FAF4AF; }
#browserblast a       { color: green; text-decoration: underline; }
.unsupported-browser  { margin-top: 41px; }

// -----------------------------------//
// FAQ SCRIPT
// -----------------------------------//

// h3.faq-open { background: #EFEFEF url(/i/design/expanding-faq/minus.png) right 12px center no-repeat; }
// h3.faq-closed { background: #EFEFEF url(/i/design/expanding-faq/plus.png) right 12px center no-repeat; }

// -----------------------------------//
// SWITCH MOBILE
// -----------------------------------//

#switch-mobile      { width:100%; margin: 0; padding: 20px 0; background:#EFEFEF; border-top:1px solid #EBEBEB; border-bottom:1px solid #EBEBEB; text-align:center; color:#777; font-family: sans-serif; font-size: 12px; display:block; clear:both; }
#switch-mobile a    { text-decoration: underline; }