// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_3636 #slider {
  position: relative; 
  height: 100vh;
  max-height: 1080px;
  min-height: 650px;
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 450px;
  z-index: 1;
}

.backstretch {
  position: relative;

  // for top of page gradient
  &:before {
    @include before(100%, 450px);
    background: url(/i/design/gradient.png) repeat-x top left;
    top: 0;
    left: 0;
  }

  &:after {
    @include before(100%, 100%);
    background: url(/i/design/gradient-bottom.png) repeat-x bottom left;
    bottom: 0;
    left: 0;
  }

  img {
    top: 0px !important;
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 60px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-size: 25px;
  font-weight: 300;
  line-height: 1.2;
  padding: 0 5%;

  @media only screen and (max-width: 1500px) {
    font-size: 22px;
  }

  @media only screen and (max-width: 1250px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 990px) {
    font-size: 18px;
  }

  span {
    display: block;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 500;

    @media only screen and (max-width: 1500px) {
      font-size: 25px;
    }

    @media only screen and (max-width: 1250px) {
      font-size: 22px;
    }

    @media only screen and (max-width: 990px) {
      font-size: 20px;
    }
  }

  img {
    display: block;
    margin: 20px auto 10px;

    @media only screen and (max-width: 1250px) {
      margin: 0 auto 20px;
    }
  }
}

#scroller {
  @include box(45px, 80px);
  background: url(/i/design/scroll-down.png) no-repeat center;
  position: absolute;
  left: 9%;
  top: 85%;
  opacity: 0;
  animation: bob 1s 2s forwards;
  z-index: 99;

  @media only screen and (max-width: 1500px) {
    left: 5%;
  }

  @media only screen and (max-width: 1250px) {
    display: none;
  }
}

@keyframes bob {
  0% {
    top: 87%
  }

  80% {
    top: 90%;
    opacity: 1;
  }

  100% {
    top: 87%;
    opacity: 1;
  }
}