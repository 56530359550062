$nav-link-bg-hov: $h1-color;
$nav-dropdown-hov: lighten($h1-color, 5%);

// -----------------------------------//
// NAVIGATION WRAPPERS
// -----------------------------------//

nav#navholder {
	z-index: 400;
	@extend .clearfix;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	top: 20px;
	width: 1100px;

	>.wrapper {
		width: 1100px;
		margin: 0 auto;
	}
}

// -----------------------------------//
// MAIN NAVIGATION
// -----------------------------------//

ul.nav {
	display: table;
	width: 1100px;
	margin: 0;
	list-style: none;

	// Top Level List
	li {
		margin: 0;
		background: none;
		position: relative;
		display: inline-block;
		text-align: center;
		white-space: nowrap;
		padding: 0;

		a {
			height: 50px;
			line-height: 50px;
			display: block;
			text-align: center;
			font-size: 20px;
			color: #fff;
			padding: 0 12px;
			font-weight: 300;

			@media only screen and (max-width: 1500px) {
				font-size: 18px;
				padding: 0 8px;
			}

			@media only screen and (max-width: 1350px) {
				font-size: 16px;
				padding: 0 5px;
			}
		}
	}

	>li:hover>a,
	>li>a:hover {
		color: #e11c00;
		text-decoration: none;
	}

	// 2nd Level List
	li {
		ul {
			position: absolute;
			left: -999em;
			margin: 0;
			padding: 5px 20px 20px 15px;
			background: none;
			opacity: 0;
			visibility: hidden;
			transition: (all 0.3s ease-in-out);
		}

		&:hover ul {
			left: auto;
			margin-left: -20px;
			opacity: 1;
			visibility: visible;
			transition: (all 0.3s ease-in-out);
		}

		&:active ul {
			transition: (opacity 0.1s ease-in-out);
		}
	}

	// 2nd Level List Items
	li li {
		float: none;
		display: block;
		padding: 0;
		height: auto;
		line-height: 35px;

		a {
			background: $nav-link-bg-hov;
			height: 39px !important;
			line-height: 28px !important;
			padding: 6px 12px;
			min-width: 170px;
			float: none;
			top: auto;
			display: block;
			position: static;
			text-align: left;
			text-decoration: none;
			font-size: 15px;
			text-transform: none;
			font-weight: 600;
		}

		&:hover,
		&:hover>a,
		a:hover,
		a:focus {
			background: $nav-dropdown-hov;
		}
	}

	// Prepend 'more' arrow to parent list items
	li li a.parent:after {
		margin: -1px 0 0 10px;
		content: url('/i/design/lite/nav-right-arrow-white.png');
		opacity: 1;
		line-height: 1;
	}

	// 3rd Level List
	ul ul {
		z-index: 505;
		padding-top: 0;
	}

	li:hover ul ul {
		left: 100%;
		top: 0;
		opacity: 0;
		visibility: hidden;
		transition: (all 0.3s ease-in-out);
	}

	ul li:hover ul {
		left: 100%;
		top: 0;
		opacity: 1;
		visibility: visible;
		transition: (all 0.3s ease-in-out);
	}

}

// -----------------------------------//
// NAVIGATION DOCKED ON SCROLL
// -----------------------------------//

#navholder.sticky.fixed {
	position: fixed;
	animation: move-down 1s ease forwards;
	background: rgba(#262164, .95);
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 100%;

	ul.nav {
		text-align: center;
	}

	ul.nav li a {
		height: 40px;
		line-height: 40px;
		font-weight: 500;
		font-size: 16px;
	}

	@keyframes move-down {
		from {
			top: -50px;
			opacity: 0;
		}

		to {
			top: 0;
			opacity: 1;
		}
	}
}